<template>
  <v-dialog v-model="show" max-width="550" persistent id="cardHistoryModal">
    <v-card>
      <v-card-title
        id="titleStyle"
        class="ma-0 pa-0 d-flex justify-space-around"
        :style="'max-height: 64px !important'"
      >
        <v-col cols="11" class="pa-4" id="titleHistory"> Histórico de atualização</v-col>

        <v-col cols="1" class="py-0 px-0">
          <v-btn id="IdBtnCloseX" @click="close(false)" :elevation="0" icon :ripple="false">
            <v-icon class="material-icons-round">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text
        :style="'height: 406px !important; max-height: 406px !important;background-color: #e7e7fa'"
        id="cardScroll2"
        class="overflow-y-auto pa-0"
      >
        <v-card-text
          v-for="(h, index) in historyImport"
          v-bind:key="index"
          :id="`idUpdateCard-${index}`"
          :class="index === 0 ? 'h-title mt-4 px-4 py-0' : 'h-title mt-1 px-4 py-0'"
        >
          <div class="h-row d-flex align-center justify-start ma-0 pa-0" style="width: 501px; height: 77px">
            <div class="d-flex flex-column pl-4" style="width: 160px; height: 45px">
              <v-subheader id="dataHoraId" class="mt-0 h-line pa-0" style="max-height: 24px">Data/hora</v-subheader>
              <v-subheader id="hourId" class="pa-0" style="max-height: 24px"
                ><strong>{{ h }}</strong></v-subheader
              >
            </div>
            <div class="d-flex align-center px-4">
              <v-divider vertical style="height: 45px; max-height: 45px; min-height: 45px; max-width: 1px"></v-divider>
            </div>
            <div class="d-flex flex-column align-start justify-center pr-4" style="width: 293px; height: 45px">
              <v-subheader id="updateSucessid" style="max-height: 24px; width: 293px"
                >Atualização realizada com sucesso
              </v-subheader>
            </div>
          </div>
        </v-card-text>
        <v-card-text id="HistoryEnd" class="h-title py-8 h-footer">
          <strong>Você visualizou todo histórico dos últimos 7 dias.</strong>
          <p>Para ter acesso as atualizações anteriores entre em <br />contato com a Agnes.</p>
        </v-card-text>
      </v-card-text>

      <v-card-actions class="pa-4" :style="'height: 80px !important; max-height: 80px !important;'">
        <v-btn
          :ripple="false"
          id="export-btn"
          :elevation="0"
          :disabled="historics == []"
          color="success"
          @click="exportar()"
          ><v-icon>mdi-download-outline</v-icon>Exportar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn :ripple="false" color="success" :elevation="0" id="IdBtnClose" @click="close(false)" style="height: 48px">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HistoryModal',
  components: {},

  data() {
    return {
      clicked: false,
      historics: [
        { id: 1, nome: 'exemplo1', hora: '05/09/2022 - 09:20' },
        { id: 2, nome: 'exemplo2', hora: '05/09/2022 - 09:20' },
        { id: 23, nome: 'exemplo13', hora: '05/09/2022 - 09:20' },
        { id: 23, nome: 'exemplo13', hora: '05/09/2022 - 09:20' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      showModal: 'wss/getHistoryModalBool',
      historyImport: 'wss/getHistoryImport',
      blobHistoryImport: 'wss/getBlobHistoryImport',
    }),
    show() {
      return this.showModal;
    },
  },

  async created() {},
  methods: {
    ...mapActions('wss', ['init', 'toggleHistoryModal', 'exportHistoryImport']),
    async exportar() {
      await this.exportHistoryImport();

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(this.blobHistoryImport);
      link.download = 'Historico de atualização - War Smart Solutions - Agnes.xlsx';

      link.click();

      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },
    close(bool) {
      this.toggleHistoryModal(bool);
    },
  },
};
</script>
<style scoped>
#subtitle {
  padding-top: 8px !important;
  font-size: 16px !important;
  color: #575767 !important;
  font-weight: regular !important;
  text-align: left !important;
}
#cardDash {
  border: 0px !important;
}
::v-deep .h-title {
  background-color: #e7e7fa !important;
}
::v-deep .h-row {
  background-color: #fff;
}
::v-deep .h-footer {
  text-align: center;
  color: #2a1c4f !important;
}
#cardGraphFilter {
  padding: 16px 16px 1px;
}
#titleStyle {
  font-size: 20px !important;
  color: '#182026' !important ;
  font-weight: bold !important;
}
#cardHistoryModal {
  width: 550px !important;
  height: 550px !important;
}
#IdBtnCloseX:hover {
  background-color: #e3ffee !important;
  border-radius: 100% !important;
  width: 32px !important;
  height: 32px !important;
}
#IdBtnCloseX:active {
  background-color: var(--v-primary-base) !important;
  color: #ffffff !important;
  box-shadow: none !important;
  width: 32px !important;
  height: 32px !important;
}
#IdBtnCloseX {
  border-radius: 100% !important;
  box-shadow: none !important;
  background: white !important;
  padding: 16px !important;
  width: 32px !important;
  height: 32px !important;
}
#IdBtnClose {
  font-size: 16px !important;
}
/* ::v-deep .v-ripple__container {
  backgound-color: var(--v-primary-base) !important;
} */
::v-deep .v-ripple__container .material-icons-round {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
::v-deep .material-icons-round::after {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
#cardScroll2 {
  flex-grow: 1;
  overflow: auto;
}
#dataHoraId {
  color: #8686a2 !important;
  font-size: 14px !important;
}
#hourId {
  color: #52526b !important;
  font-size: 16px !important;
}
#IdBtnClose:hover {
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  font-size: 16px !important;
  box-shadow: none !important;
}
#export-btn {
  color: var(--v-primary-base);
  font-size: 16px !important;
  background-color: white !important;
  border: 1px solid primary;
}
#export-btn .v-icon {
  padding-right: 8px !important;
}

#export-btn:hover {
  font-size: 16px !important;
  background-color: #e3ffee !important;
  color: var(--v-primary-base);
  box-shadow: none !important;
  opacity: 1 !important;
}
#updateSucessid {
  color: #288569 !important;
  font-size: 14px !important;
}
#historyEnd {
  color: #2a1caf !important;
  font-size: 14px !important;
}
</style>
